const initMap = function () {
  const mapEl = document.getElementById('map');
  if (!mapEl) {
    return;
  }
  // Initialise map
  var map = new google.maps.Map(mapEl, {
    center: {
      lat: 52.7917636, 
      lng: -2.858130200000005
    },
    mapId: 'e547d98859c937b2',
    zoom: 7,
    scrollwheel: false,
    streetViewControl: false
  });

  var mapData = document.getElementById('mapData').children;

  for (let element of mapData) {
    map.data.loadGeoJson(element.dataset.geojson);
  }

  // Set shape styles
  map.data.setStyle({ 
    fillColor: "#FF880A",
    fillOpacity: 0.5, 
    strokeWeight: 1, 
    strokeColor: '#FF880A' 
  });

  // Setup infowindows
  var infoWindow = new google.maps.InfoWindow();
  
  // Open infowindows with name on mouseover
  map.data.addListener('mouseover', function(event) {
    var name = event.feature.getProperty('name');
    infoWindow.setContent(
      '<div style="width:150px; text-align:center;">' + name + '</div>'
    );
    infoWindow.setPosition(event.latLng);
    infoWindow.setOptions({ pixelOffset: new google.maps.Size(0, -10) });
    infoWindow.open(map);
    map.data.overrideStyle(event.feature, { fillOpacity: 0.8 });
  });
  
  // Close infowindows on mouseout
  map.data.addListener('mouseout', function() {
    infoWindow.close(map);
    map.data.revertStyle();
  });

  // Setup hotlink redirects for when area is clicked
  map.data.addListener('click', function (event) {
    var hotlink = event.feature.getProperty('hotlink');
    if (hotlink) {
      document.location.href = hotlink;
    }
  });
};

function checkForMapScript() {
  const mapParent = document.getElementById('listings-map');
  if (!mapParent) {
    return;
  }
  window.google = null;
  const newScript = document.createElement("script");
  newScript.async = true;
  newScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyApF6YhZzgcWITmtn7lbCIRQ1blpLYybsQ&callback=initMap';
  mapParent.append(newScript);
}

window.initMap = initMap;

const map = {
  checkForMapScript: checkForMapScript
}

export default map;