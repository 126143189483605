import Player from '@vimeo/player';

const videos = Array.from(document.querySelectorAll('.js-video'));
let currentVideoPlaying = {};

function newYouTubePlayer(clicked) {
  const el = clicked.closest('.js-video');
  el.classList.add('video-is-clicked');
  const elId = el.id;
  // Define onPlayerReady function in here so it's scoped to the player
  function onPlayerReady(event) {
    event.target.playVideo();
  }
  // Build videoId and initialisation options
  let start = 0;
  let videoId = null;
  const url = clicked.href;
  // Get querystring portion of URL
  const qs = url.split('?')[1];
  // Split querystring into params
  const params = new URLSearchParams(qs);
  start = params.get('t');
  videoId = params.get('v');
  // Youtube replaces whatever DOM element you pass in, so append an empty container div
  const container = document.createElement('div');
  clicked.append(container);
  // Construct the new player
  const player = new YT.Player(container, {
    origin: window.location.origin,
    height: clicked.offsetHeight,
    width: clicked.offsetWidth,
    videoId: videoId,
    playerVars: {
      start: start
    },
    events: {
      'onReady': onPlayerReady,
      'onStateChange': function(event) {
        if (event.data === 1 && currentVideoPlaying) {
          pauseCurrentPlayNew({
            newId: elId,
            newPlayer: player,
            newType: 'youtube'
          });
        }
      }
    }
  });
}

function initYouTubeIframeAPI(clicked) {
  window.onYouTubeIframeAPIReady = function() {
    newYouTubePlayer(clicked);
  }
}

function isYouTube(url) {
  return url.includes('watch?v=') || url.includes('.be/');
}

function isVimeo(url) {
  return url.includes('vimeo');
}

function initYouTubeVideo(e) {
  e.preventDefault();
  // Check youtube URL format
  const clicked = e.target.closest('.js-video');
  const videoInitialised = clicked.dataset.init;
  if (videoInitialised === 'true') {
    return;
  } else {
    clicked.dataset.init = 'true';
  }
  // Check if youtube script is already on the page
  if (window.YT) {
    newYouTubePlayer(clicked);
    return;
  }
  // If not, add it..
  initYouTubeIframeAPI(clicked);
  // Load the IFrame Player API code asynchronously.
  const tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  tag.id = 'youtube-script';
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  // This triggers the global onYouTubeIframeAPIReady
}

function initVimeoVideo(e) {
  e.preventDefault();
  const clicked = e.target.closest('.js-video');
  const url = clicked.href;
  const elId = clicked.id;
  const width = clicked.offsetWidth;
  const player = new Player(elId,  {
    url: url,
    width: width,
  });
  player.ready().then(function(e) {
    const container = clicked.closest('.js-video');
    container.classList.add('video-is-clicked');
    player.play();
  });

  player.on('play', function() {
    pauseCurrentPlayNew({
      newId: elId,
      newPlayer: player,
      newType: 'vimeo'
    });
  });
}

function pauseCurrentPlayNew(params) {
  const { newId, newPlayer, newType } = params;
  const { currentPlayer, currentType, currentId } = currentVideoPlaying;
  if (currentId !== newId) {
    if (currentType === 'vimeo') {
      currentPlayer.pause();
    } else if (currentType === 'youtube') {
      currentPlayer.pauseVideo();
    }

    currentVideoPlaying = {
      currentId: newId,
      currentPlayer: newPlayer,
      currentType: newType
    }
  }
}

function checkVideoType(e) {
  const clicked = e.target;
  const url = clicked.href;
  if (isYouTube(url)) {
    initYouTubeVideo(e);
  } else if (isVimeo(url)) {
    initVimeoVideo(e);
  }
}

export default function() {
  document.addEventListener('click', function(e) {
    const clicked = e.target;
    const video = clicked.closest('.js-video');
    if (!video) {
      return;
    }
    checkVideoType(e);
  });
}
