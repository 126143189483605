let listingsList;
let listingsMap;

function showList() {
  if (listingsList) {
    listingsList.classList.add("listings-list-is-active");
    listingsMap.classList.add("hide");
  };
}

function showMap() {;
  if (listingsMap) {
    listingsMap.classList.remove("hide");
    listingsList.classList.remove("listings-list-is-active");
  };
}

const mapFilters = {
  init() {
    document.addEventListener("change", function (e) {
      const clicked = e.target;
      const listingsMapBtn = clicked.closest("#listings-map-btn");
      const listingsListBtn = clicked.closest("#listings-list-btn");
      listingsList = document.getElementById('listings-list');
      listingsMap = document.getElementById('listings-map');
      if (listingsListBtn) {
        showList();
      } else if (listingsMapBtn) {
        showMap();
      }
    });
  },
};

export default mapFilters;
