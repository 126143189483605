import page from './modules/page';
import dropdownModule from './modules/dropdown';
import mobile from './modules/mobile';
import filter from './modules/filter';
import header from './modules/header';
import video from './modules/video';
import carousel from './modules/carousel';
import search from './modules/search';
import filtersModal from './modules/filtersModal';
import mobileSubNav from './modules/mobileSubNav';
import './modules/map';
import map from './modules/map';
import mapFilters from './modules/map-filters';
import accordion from './modules/accordion';

page();
dropdownModule.init();
mobile.init();
filter();
header.initResizeListener();
video();
carousel();
search.init();
filtersModal.init();
mobileSubNav();
mapFilters.init();
accordion();

// if tab key is used, add class to show outline styles
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);

window.addEventListener('load', function() {
  map.checkForMapScript();
})