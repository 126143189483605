import gsap from 'gsap';
import search from './search';
const button = document.getElementById('mobile-nav-button');
const body = document.body;
const activeClass = 'mobile-nav-is-active';
const nav = document.getElementById('mobile-nav');


function hide() {
  body.classList.remove(activeClass);
  gsap.to(nav, {
    xPercent: -100,
    duration: 0.1,
    ease: 'none'
  })
}

function show() {
  body.classList.add(activeClass);
  gsap.to(nav, {
    xPercent: 100,
    duration: 0.1,
  })
}

function toggle() {
  if (body.classList.contains(activeClass)) {
    search.hideSearch();
    hide();
  } else {
    show();
  }
}

function init() {
  button.addEventListener('click', toggle);
}

const mobile = {
  hide: hide,
  init: init
}

export default mobile;