import Glide from '@glidejs/glide';

function init(el) {
  const carousel = new Glide(el).mount();
}

export default function() {
  const carousels = Array.from(document.querySelectorAll('.js-carousel'));
  if (!carousels.length) {
    return;
  }
  carousels.forEach(carousel => init(carousel));
}
