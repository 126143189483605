function openModal() {
  const modal = document.querySelector('.js-filters-modal');
  if (modal) {
    modal.classList.add("filters-mobile-is-active");
  }
}

function closeModal() {
  const modal = document.querySelector('.js-filters-modal');
  if (modal) {
    modal.classList.remove("filters-mobile-is-active");
  }
}

const filtersModal = {

  init() {
    document.addEventListener('click', function(e) {
      const clicked = e.target;
      const openButton = clicked.closest('.js-filters-mobile-open-button');
      const closeButton = clicked.closest('.js-close-filters-modal');
      if (openButton) {
        openModal();
      } else if (closeButton) {
        closeModal();
      }
    });
  },
};



export default filtersModal;
