import barba from '@barba/core';
import gsap from 'gsap';
import { pathToRegexp, match, parse, compile } from "path-to-regexp";
import carousel from './carousel';
import mobile from './mobile';
import search from './search';
import map from './map';
import header from './header';

const pageAnimEl = document.getElementById('page-anim');
const body = document.body;

function initResizeListener() {
  // Reference: http://www.html5rocks.com/en/tutorials/speed/animations/
  let ticking = false;

  window.addEventListener('resize', function(e) {
    if (!ticking) {
      window.requestAnimationFrame(function() {
        // Reset page animation if user resizes page during animation
        resetAnim();
        ticking = false;
      });

      ticking = true;
    }
  });
}

function resetAnim() {
  const activeAnim = gsap.getTweensOf(pageAnimEl)[0];
  if (!activeAnim) {
    return;
  }
  activeAnim.progress(0);
}

function leaveAnim() {
  return gsap.fromTo(pageAnimEl, {
    display: 'block',
    xPercent: -100,
    "clip-path": "polygon(0 0, 75% 0, 100% 100%, 0% 100%)",
  }, {
    xPercent: 100,
    "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
    duration: 0.5,
    ease: 'power2.out',
    immediateRender: false
  });
}

function afterAnim() {
  return gsap.fromTo(pageAnimEl, {
    xPercent: 100,
  }, {
    xPercent: 200,
    display: 'none',
    duration: 0.5,
    ease: 'power2.out',
    immediateRender: false
  });
}

export default function() {
  body.classList.remove('no-js');

  document.addEventListener('DOMContentLoaded', function(e) {
    body.classList.remove('page-is-loading');
  });

  initResizeListener();

  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }

  const cacheIgnore = pathToRegexp("/news\\?filters=(.*)");

  let scrollY = 0;
  barba.hooks.beforeLeave((data) => {
    scrollY = window.scrollY;
  });

  barba.hooks.beforeEnter((data) => {
    // Re-run any initialising scripts that depend on elements being present on the page (as opposed to click events):
    carousel();
    header.checkForSubNav(data.next.url.href);
  });

  barba.hooks.after((data) => {
    const { trigger, current, next } = data;
    if (trigger === 'back' || trigger === 'forward' || trigger === 'popstate') {
      if (current.url.href.includes('news?filters') && next.url.href.includes('news?filters')) {
        // Keep scroll position when filtering
        window.scrollTo(0, scrollY);
      }
    } else {
      // Reset scroll if navigating to new page
      window.scrollTo(0, 0);
    }
    map.checkForMapScript();
  });


  barba.init({
    debug: true,
    timeout: 10000,
    transitions: [
      {
        name: 'opacity-transition',
        beforeLeave() {
          body.classList.add('page-is-changing');
          search.hideSearch();
          mobile.hide();
        },
        leave: leaveAnim,
        beforeOnce(barbaObject) {
          header.setActiveNavLink({ barbaObject: barbaObject, initialPageLoad: true });
        },
        beforeEnter(barbaObject) {
          header.setActiveNavLink({ barbaObject: barbaObject, initialPageLoad: false });
          body.classList.remove('page-is-changing');
        },
        after: afterAnim
      },
      {
        name: 'self',
        enter() {
          body.classList.remove('loading-ajax-cards')
          gsap.from('.js-ajax-card', {
            opacity: 0,
            y: 10,
            duration: 0.5,
            delay: 0.2,
            stagger: 0.1,
          });
        },
        beforeLeave() {
          search.hideSearch();
          body.classList.add('loading-ajax-cards');
        }
      }
    ],
  });
}
