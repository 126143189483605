import barba from '@barba/core';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
const loadingClass = 'loading-ajax-cards';
const resetClass = 'reset-checkboxes-is-active';

function ajax(options) {
  const { url, paginate = false } = options;
  const body = document.body;
  // Add css class - temporarily disable further clicks on filters and cards
  body.classList.add(loadingClass);

  barba.request(url).then((res) => {
    const filterSection = document.getElementById('filters');
    barba.history.add(url, 'barba');
    const parser = new DOMParser();
    const resHTML = parser.parseFromString(res, "text/html");
    const newContainer = resHTML.getElementById('ajax-cards');
    const newContent = newContainer.innerHTML;
    const ajaxContainer = document.getElementById('ajax-cards');
    // Prevent height 'jump' when swapping content
    const height = ajaxContainer.offsetHeight;
    ajaxContainer.style.height = `${height}px`;
    ajaxContainer.innerHTML = newContent;
    ajaxContainer.style.height = `auto`;

    gsap.from('.js-ajax-card', {
      opacity: 0,
      y: 10,
      duration: 0.5,
      delay: 0.2,
      stagger: 0.1,
    });

    if (paginate) {
      gsap.to(window, {
        scrollTo: {
          y: filterSection,
          offsetY: () => {
            const header = document.getElementById('header');
            const headerHeight = header.offsetHeight;
            return headerHeight;
          }
        },
        duration: 0.2,
      })
    }
  }).catch((err) => {
    console.log(err);
  }).finally(() => {
    body.classList.remove(loadingClass);
  });
}


function updateFilters(filter) { 
  const href = window.location.href;
  // Always remove page number when updating filters
  let url = href.includes('/page') ? href.split('/page')[0] : href.split('?filters')[0];
  
  if (filter.classList.contains('js-reset-checkboxes')) {
    // Sync mobile and desktop reset theme buttons/checkboxes
    const resetButtons = document.querySelectorAll('.js-reset-checkboxes');
    resetButtons.forEach(button => button.classList.add(resetClass));
    const allCheckboxFilters = document.querySelectorAll('input[type="checkbox"].js-filter');
    allCheckboxFilters.forEach(function(checkBox) {
      checkBox.checked = false;
    });  
  } else if (filter.type === 'checkbox') {
    // Sync mobile and desktop reset checkbox buttons
    const resetButtons = document.querySelectorAll('.js-reset-checkboxes');
    resetButtons.forEach(button => button.classList.remove(resetClass));  
  }
  // Find and sync mobile and desktop checkboxes and radio inputs
  let filterId = filter.id;
  if (filterId.includes('mobile-')) {
    filterId = filterId.split('mobile-')[1];  
  }
  
  let matches = Array.from(document.querySelectorAll(`.js-filter[id$="${filterId}"]`));  
  matches.forEach(match => match.checked = filter.checked);
  // Then rescan for checked checkboxes and radios
  let updatedSelected = Array.from(document.querySelectorAll('.js-filter:checked'));
  // Exclude the reset buttons/inputs
  updatedSelected = updatedSelected.filter(item => !item.id.includes('filter-all-types') && !item.classList.contains('js-reset-checkboxes'))
  // Exclude mobile inputs for rebuilding URL so you don't get duplicate filters added to the URL
  updatedSelected = updatedSelected.filter(item => !item.id.includes('mobile'));
  if (updatedSelected.length) {
    const qString = updatedSelected.reduce((p, c, i) => {
      return p + c.value + `${i < updatedSelected.length - 1 && updatedSelected.length > 1 ? ',' : ''}`;
    }, '?filters=');
    url = url + qString;
  }
  
  ajax({url: url});
}

function paginate(link) {
  const url = decodeURIComponent(link.href);
  ajax({
    url: url,
    paginate: true
  });
}

export default function() {
  document.addEventListener('click', function(e) {
    // Allow command-click and control-click to open new tab
    // http://henleyedition.com/allow-ctrl-command-click-using-event-preventdefault/
    if (e.metaKey || e.ctrlKey) {
      return;
    }
  });

  document.addEventListener('change', function(e) {
    const target = e.target;
    const filter = target.closest('.js-filter');
    if (filter) {
      updateFilters(filter);
    }
  });

  document.addEventListener('click', function(e) {
    const clicked = e.target;
    const resetButton = clicked.closest('.js-reset-checkboxes');
    const paginateLink = clicked.closest('.js-filter-paginate-link');
    if (resetButton) {
      e.preventDefault();
      updateFilters(resetButton);
    }
    if (paginateLink) {
      e.preventDefault();
      paginate(paginateLink);
    }
  })
}