import gsap from 'gsap';
const menuClass = 'mobile-sub-is-active';

function open() {
  const menu = document.getElementById('mobile-nav-sub-menu');
  menu.classList.add(menuClass);
  const tl = gsap.timeline();
  tl.set(menu, {
    height: 'auto',	
  })
  .from(menu, {
    height: 0,
    duration: 0.2,
    ease: 'power2.out',
    immediateRender: false
  });
}

function close() {
  const menu = document.getElementById('mobile-nav-sub-menu');
  menu.classList.remove(menuClass);
  gsap.to(menu, {
    height: 0,
    duration: 0.1,
  });
}

function toggle() {
  const menu = document.getElementById('mobile-nav-sub-menu');
  if (menu.classList.contains(menuClass)) {
    close();
  } else {
    open();
  }
}

export default function() {
  document.addEventListener('click', function(e) {
    const clicked = e.target;
    const button = clicked.closest('#mobile-nav-sub-button');
    if (button) {
      toggle();
    } 
  });
}