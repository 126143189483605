const activeLinkClass = 'nav-link-is-active';

const body = document.body;
let prevScrollY = window.scrollY;

// TODO: change name to scrollListener
function initResizeListener() {
  // Setup a timer
  let timeout;
  // Listen for scroll events
  window.addEventListener('scroll', function(event) {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout);
  	}
    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function() {
      // Run our scroll function
      checkScroll();
  	});
  }, false);
}

function checkScroll() {
  const newScrollY = window.scrollY;

  if (newScrollY > prevScrollY || newScrollY === 0) {
    body.classList.remove('scrolling-up');
  } else {
    body.classList.add('scrolling-up');
  }
  prevScrollY = newScrollY;
}

function setActiveNavLink(params) {
  const { barbaObject, initialPageLoad } = params;
  const { next } = barbaObject;
  const newPageUrl = next.url.href.split('://')[1];
  // Plural because there may be multiple links with the same href e.g. About Us and Our Story
  const matchNavLinks = Array.from(document.querySelectorAll(`a[href*="${newPageUrl}"].js-nav-link`));
  const currentLinks = Array.from(document.querySelectorAll(`.${activeLinkClass}`));
  // If it's not the first time the page has loaded (i.e. it's a page transition), remove the active class from the current link:
  if (!initialPageLoad) {
    if (currentLinks.length) {
      currentLinks.forEach(link => link.classList.remove(activeLinkClass));
    }
  }
  if (matchNavLinks) {
    matchNavLinks.forEach(link => link.classList.add(activeLinkClass));
  }
}

function checkForSubNav(url) {
  const urlString = url.split('://')[1];
  const activeLink = document.querySelector(`a[href$="${urlString}"].js-show-nav-sub`);
  if (!activeLink) {
    document.body.classList.remove('nav-sub-is-active');
  } else {
    document.body.classList.add('nav-sub-is-active');
  }
}

const header = {
  initResizeListener: initResizeListener,
  checkForSubNav: checkForSubNav,
  setActiveNavLink: setActiveNavLink,
}

export default header;